<template>
  <div>
    <vx-card class="mt-8">
        <div class="card-title">
          <h2>Audit Log</h2>          
        </div>
        <div class="common-table"> 
            <div class="table-row-select">
                <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item,index) in recordList"
                />
                </vs-select>
            </div>        
            <vs-table ref="tableData" :data="auditLog" search :max-items="perPage" pagination>
                <template slot="thead">                
                    <vs-th sort-key="sortableCreatedDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                    <vs-th sort-key="User_Name">{{ LabelConstant.tableHeaderLabelUser }}</vs-th>
                    <vs-th sort-key="Action_Type">{{ LabelConstant.tableHeaderLabelAction }}</vs-th>                
                    <vs-th sort-key="Notes">{{ LabelConstant.tableHeaderLabelNotes }}</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="tr.sortableCreatedDate">
                            {{tr.Date_Created}}
                        </vs-td>
                        <vs-td :data="tr.User_Name">
                            {{tr.User_Name}}
                        </vs-td>
                        <vs-td :data="tr.Action_Type">
                            <vs-chip :color="tr.Action_Type | filterColor">{{tr.Action_Type}}</vs-chip>
                        </vs-td>
                        <vs-td :data="tr.Notes">
                            {{tr.Notes}}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{auditLog.length|tableRecordNumberFormatter}} entries</p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{auditLog.length|tableRecordNumberFormatter}} entries)</p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
        </div>
	</vx-card>
  
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import vSelect from 'vue-select'

export default {
    components: {
        VxCard,    
        vSelect
    },
    data() {
        return {    
            auditLog: [],
            /* Table */
            perPage: 5,
            recordList: [
                {text: '5', value: 5},
                {text: '10', value: 10},
                {text: '25', value: 25},
                {text: '50', value: 50},
                {text: '100', value: 100}
            ],
        };
    },
    created(){
        this.getAuditLogList()
    },
    filters:{
        filterColor: function(value){
            if(value.includes('Add') || value.includes('Activation')){
                return 'success'
            }else if(value.includes('modified') || value.includes('Modified') || value.includes('Modify') || value.includes('Update') || value.includes('Adjust') || value.includes('Changed')){
                return 'warning'
            }else if(value.includes('delete') || value.includes('Delete')){
                return 'danger'
            }else{
                return 'gray'
            }
        }
    },
    methods: {
        getAuditLogList(){
        this.$vs.loading()
            this.axios.get("/ws/AuditLog/GetAuditLogList").then(response => {
                let data = response.data;
                this.auditLog = data
                this.auditLog.map(function(manipulatedData) {
                    manipulatedData.sortableCreatedDate = new Date(manipulatedData.Date_Created);
                    return manipulatedData;
                });
                
                // this.auditLog.sort(this.GetSortOrder("sortableCreatedDate", 'ASC')); //Pass the attribute to be sorted on
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        }
    },
    computed: {
		isTableRecordEntriesDisplay(){
			if(this.auditLog.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
        isSearch: function () {
            if(this.auditLog.length > 0){
                if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
                    return false
                }else{
                    return true
                }
            }
        },
        showingDataFrom: function () {
            if(this.auditLog.length > 0){
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1 
            }
        },
        showingDataTo: function () {
            if(this.auditLog.length > 0){
                let totalPages = this.$refs.tableData.getTotalPages
                if(totalPages == this.$refs.tableData.currentx){
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                }else{
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        },
        totalSearchedData: function () {
            if(this.auditLog.length > 0){
                return this.$refs.tableData.queriedResults.length
            }
        },
        showingSearchedDataTo: function () {
            if(this.auditLog.length > 0){
                let totalPages = this.$refs.tableData.getTotalPagesSearch
                if(totalPages == this.$refs.tableData.currentx){
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                }else{
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        }
    }
};
</script>
